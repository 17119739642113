<template>
  <q-layout class="background-layout">
    <div v-if="showPreload" class="row items-center justify-center" style="width: 200px;position: absolute; top: 0; bottom: 0; left: 0; right: 0; margin: auto auto">
      <lottie-animation
          ref="anim"
          :animationData="require('../assets/preload-animation.json')"
          :loop="true"
          :autoPlay="true"
          :speed="1"
          style="width: 200px; height: 200px"
      />
    </div>

    <div v-else class="row items-center justify-center" style="min-height: 100vh">
      <div class="col-3">
        <q-form @submit="submit" style="padding: 40px 40px; background-color: #ffffff; border-radius: 5px;">
          <div class="text-h5" style="margin-bottom: 15px">Вход в систему</div>
          <q-input class="margin-for-input" outlined type="login" v-model="login" label="Пользователь" :rules="[ val => val && val.trim().length > 0 || 'Пожалуйста укажите пользователя']"/>
          <q-input class="margin-for-input" outlined :type="isPwd ? 'password' : 'text'" v-model="password" label="Пароль" :rules="[ val => val && val.trim().length > 0 || 'Введите пароль']">
            <template v-slot:append>
              <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
          <div>
            <q-btn label="Войти" type="submit" :disable="canEntrance" color="primary"/>
          </div>
        </q-form>
      </div>
    </div>

  </q-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import LottieAnimation from 'lottie-web-vue';

export default {
  name: "Login",
  components: {LottieAnimation},
  data() {
    return {
      showPreload: false,
      login: null,
      password: null,
      isPwd: true,
    }
  },

  methods: {
    async submit() {
      this.showPreload = true
      // setTimeout(() => this.loginProcess = false, 2000)

      const login = this.login
      const password = this.password

      try {
        await this.server.auth.login({
          email: login,
          password
        }, { refresh: { auto: true, time: 30000 } })
      }
      catch (e) {
        this.$q.notify({
          message: `Ошибка входа: ${e.message}`,
          color: 'red'
        })

        console.error('error', e.message)
        this.showPreload = false
        return
      }

      await this.$router.push({ path: '/' })
    }
  },

  computed: {
    ...mapGetters({
      server: 'server/instance'
    }),
    canEntrance() {
      return !(this.login && this.password)
    },
  }
}
</script>

<style scoped>
.background-layout {
  background-image: url("../assets/login-background.webp");
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-size: cover;
}
.margin-for-input {
  margin-bottom: 10px;
}
</style>